export const firebaseConfig = {
  apiKey: "AIzaSyDnnUSyIl3PBkRgqOk6w1LeFyBDFDHrv9s",
    authDomain: "barbaravokatis.firebaseapp.com",
    databaseURL: "https://barbaravokatis-default-rtdb.firebaseio.com",
    projectId: "barbaravokatis",
    storageBucket: "barbaravokatis.appspot.com",
    messagingSenderId: "164048217143",
    appId: "1:164048217143:web:22159595cde8e969fe0bb2",
    measurementId: "G-Z00THSP8S5"
};
